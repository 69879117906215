var expireMinutes = 15 * 60 * 1000; //mins
var expireTimeRemember = 30 * 24 * 60 * 60 * 1000; //days
var timeToCheckSession = 0.5 * 60 * 1000; // 30 seconds
var loginPath = window.standardUrl();
var sessionModal = '#modalSessionExpired';
var homepageUrl = window.standardUrl('homepage.html');
var whiteList = [homepageUrl, window.standardUrl('reset-pwd.html'), window.standardUrl('manage-account.html')];
var pagesHiddenItemFooter = [loginPath, window.standardUrl('reset-pwd.html'), window.standardUrl('register.html')];

(function (global, $) {
  var loggingTime = 'loggingTime';

  global.login = function (email, pwd, expire, event) {
    if (!email || !pwd) {
      return;
    }
    var currentTime = new Date($.now());
    $.cookie(loggingTime, JSON.stringify(currentTime.toGMTString()), { path: '/' });
  }
})(window, jQuery);

$(document).ready(function () {
  var urlPath = window.location.pathname;

  var emailCookie = $.cookie('email');
  var timeCookie = $.cookie('loggingTime');
  var $formLogin = $('form[name="formLogin"]');

  if (!!emailCookie) {
    $formLogin.find('input[name="email"]').val(emailCookie);
    $formLogin.find('input[type="checkbox"]').prop('checked', true);
  }

  if (!!timeCookie) {
    $('.js-login-time').text(JSON.parse(timeCookie));
  }

  function checkDisplayFooterItem() {
    if (pagesHiddenItemFooter.indexOf(urlPath) > -1) {
      $('.js-hidden-footer-item').addClass('hidden');
    }
  }

  function pad(number) {
    return number < 10 ? '0' + number : number;
  }

  function convertMinutesToHours(time) {
    time = Number(time);
    var hours = pad(Math.floor(time / 3600));
    var minutes = pad(Math.floor(time % 3600 / 60));
    var seconds = pad(Math.floor(time % 3600 % 60));

    return [hours, minutes, seconds].join(':');
  }

  $('.js-logged-out').click(function () {
    var loggedTime = new Date($.now());
    $.cookie('loggedTime', JSON.stringify(loggedTime.toGMTString()), { path: '/' });

    window.location.href = window.standardUrl() + '?loggedOut=true';
  });

  function checkSessionExpired () {
    setInterval(function () {
      var loggedModal = $('.js-modal-logged-out').hasClass('in');

      if (!$.cookie('LOGIN_COOKIE') && whiteList.indexOf(urlPath) > -1 && !loggedModal) {
        $(sessionModal).modal('show');
      }
    }, timeToCheckSession);
  }

  function showLoggedOutModal() {
    var loggedTime = $.cookie('loggedTime');

    if (!!loggedTime) {
      var loggingTime = Date.parse(timeCookie);
      var dateDiff = (Date.parse(loggedTime) - loggingTime) / 1000;
      var durationTime = Math.abs(Math.round(dateDiff));

      $('.js-logged-time').text(JSON.parse(loggedTime));
      $('.js-duration').text(convertMinutesToHours(durationTime));
    }

    var param = window.getParameterByName('loggedOut');
    if (param) {
      $('#modal-logged-out').modal('show');
    }
  }

  //checkSessionExpired ();
  checkDisplayFooterItem();
  showLoggedOutModal();
});
